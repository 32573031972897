import { instance } from "lib/axios";
export async function login(data) {
  const response = await instance.post(`/api/user/login`, data);

  if (response?.data?.data?.access_token) {
  
    localStorage.setItem("token", response?.data?.data?.access_token);
    localStorage.setItem("user_id", response?.data?.data?.user_id);
    localStorage.setItem("refresh_token", response?.data?.data?.refresh_token);
    localStorage.setItem("username", response?.data?.data?.username);
    localStorage.setItem("role", response?.data?.data.role);
    localStorage.setItem("user_email", response?.data?.data?.email);
    localStorage.setItem("first_name", response?.data?.data?.first_name);
    localStorage.setItem("last_name", response?.data?.data?.last_name);
  }
  return response;
}
