import Notes from "components/Comments/Notes";
import { Spinner } from "components/common/Spinner";
import { NavHeader } from "components/layout/NavHeader";
import { useEffect, useState } from "react";
import { Accordion, Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  FileEarmark,
  FileEarmarkPdf,
  JournalCheck,
  XCircle,
  ArchiveFill
} from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { globalStoreV2 } from "store/globalStoreV2";
import { useGetVendorDetails, useGetVendorDocumentAnalytics } from "./api";
import Header from "./components/Header";
import VendorDetailsTable from "./components/VendorDetails";
import { instance } from "lib/axios";
import { PdfViewer } from "modules/InvoiceDetails/components/PdfViewer";
import { GaugeChart } from "@carbon/charts-react";

export default function VendorDetails() {
  const { vendorID } = useParams();
  const { actual_vendor_name } = globalStoreV2();
  const { data, isLoading } = useGetVendorDetails(vendorID);
  // const {data:venorNotes,isLoading:loadingNotes}=useGetVendorNotes(vendorID)
  const tooltip = <Tooltip id="tooltip">View Vendor Notes</Tooltip>;
  const pdfTooltip = <Tooltip id="tooltip">View Vendor Pdfs</Tooltip>;
  const [showNotes, setShowNotes] = useState(false);
  const [showPdfs, setShowPdfs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfsData, setPdfsData] = useState();
  const getInvoicePdfs = async () => {
    setLoading(true);
    const body = {
      fetch_mode: "vendor_id",
      vendors: [vendorID]
    };
    const response = await instance.post("/api/vendor/pdf/", body);
    if (response?.status === 200) {
      // setPdfsData(response?.data?.data);
      setPdfsData(response?.data?.data?.[vendorID]);
      setLoading(false);
    } else {
      // setLoading(false)
    }
  };
  useEffect(() => {
    if (showPdfs) {
      getInvoicePdfs();
    }
  }, [showPdfs]);
  const { vendorID: vendor_id } = useParams();
  const { data: vendorAnalytics, isLoading: loadingAnalytics } = useGetVendorDocumentAnalytics(vendor_id)

  return (
    <>
      <NavHeader view="Vendor Details" />

      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <Header
            text={`Vendor Details for ${actual_vendor_name} `}
            archived={data?.data?.archive_status}
          >
            <OverlayTrigger placement="left" overlay={tooltip}>
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowNotes(true)}
                disabled={vendorID === null}
              >
                <JournalCheck
                  height={20}
                  width={20}
                  fill="white"
                  className="cursor-pointer warning"
                />
              </Button>
            </OverlayTrigger>
            {showPdfs ? (
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowPdfs(!showPdfs)}
              >
                <XCircle />
              </Button>
            ) : (
              <OverlayTrigger placement="left" overlay={pdfTooltip}>
                <Button
                  variant="success"
                  className="mx-2"
                  onClick={() => setShowPdfs(!showPdfs)}
                >
                  <FileEarmarkPdf
                    height={20}
                    width={20}
                    fill="white"
                    className="cursor-pointer warning"
                  />
                </Button>
              </OverlayTrigger>
            )}
          </Header>
          <Row style={{ overflow: "hidden", margin: 0 }}>
            <Col>
              <div className="px-5 d-flex mt-1 p-2 w-100 justify-content-center ">
                <Accordion className="my-3 w-100">
                  <Accordion.Item >
                    <Accordion.Header>Document Extraction Efficiency</Accordion.Header>
                    <Accordion.Body >
                      <div className="d-flex align-items-center gap-4 ">


                        <div className="w-100  justify-content-center p-4  " style={{ flexDirection: "column", display: "flex" }}>
                          <p style={{ fontWeight: 600 }}>Total  Documents  Synced :-  {vendorAnalytics?.data?.total_synced_documents}   </p>
                          <p style={{ fontWeight: 600 }}> Analysis Perod :- {vendorAnalytics?.data?.start_date?.split("T")?.[0]} to {vendorAnalytics?.data?.end_date?.split("T")?.[0]}</p>

                        </div>
                        <div className="d-flex gap-4 justify-content-between">
                          <div style={{ display: 'flex', flexDirection: "column", gap: 5, justifyContent: "center", alignContent: 'center', alignItems: "center", minWidth: "400px" }}>
                            <GaugeChart className="border" data={[{
                              group: 'value',
                              value: vendorAnalytics?.data?.metadata_extraction_efficiency
                            }]} options={{
                              // title: 'Metadata Table Extraction Efficiency',
                              resizable: false,
                              width: "300px",
                              height: '150px',
                              gauge: {
                                type: 'semi',
                                status: 'danger'
                              }
                            }} />
                            <p style={{ marginLeft: -100 }} className="mt-2 ">Metadata Table Extraction Efficiency</p>
                          </div>
                          <div style={{ display: 'flex', flexDirection: "column", gap: 5, justifyContent: "center", alignContent: 'center', alignItems: "center", minWidth: "400px" }}>
                            <GaugeChart data={[{
                              group: 'value',
                              value: vendorAnalytics?.data?.table_extraction_efficiency
                            }]} options={{
                              // title: 'Table Extraction Efficiency',
                              resizable: false,
                              width: "300px",
                              height: '150px',
                              gauge: {
                                type: 'semi',
                                status: 'danger'
                              }
                            }} />
                            <p style={{ marginLeft: -100 }} className="mt-2 ">Table Extraction Efficiency</p>
                          </div>
                        </div>
                      </div>

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>
              <VendorDetailsTable
                data={data}
              // handleSaveVendorDetails={handleSaveVendorDetails}
              />
            </Col>
            {showPdfs && !loading && (
              <Col className="pt-5">
                <PdfViewer
                  pdfUrls={pdfsData}
                  isLoading={loading}
                  multiple={true}
                />
                {!pdfsData && <p>No Pdfs Available</p>}
              </Col>
            )}
          </Row>
          <Notes
            show={showNotes}
            setShow={setShowNotes}
            name="Vendor Notes"
            // data={venorNotes}
            vendorId={vendorID}
          />
        </>
      )}
      <ToastContainer />
    </>
  );
}
