import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useGetInvoiceAssignment } from "../api";
import { Skeleton } from "antd";
import calculateTimeDifference from "../helpers/calculateLeftTime";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowDown, ArrowUp, PatchCheckFill } from "react-bootstrap-icons";
import { HomePagination } from "components/layout/HomePagination";
import { invoiceDetailStore } from "store/invoiceDetailsStore";
import useUpdateParams from "hooks/useUpdateParams";

export default function Assignment() {
  let [searchParams] = useSearchParams();
  const { setTotalPages } = invoiceDetailStore();
  let assigned_at_order = searchParams.get("assigned_at_order") || "desc";
  let payload = {
    page: searchParams.get("page") || 1,
    page_size: searchParams.get("page_size") || 10,
    restaurant: searchParams.get("restaurant"),
    vendor: searchParams.get("vendor"),
    assigned_to: searchParams.get("assigned_to"),
    start_date: searchParams.get("start_date"),
    end_date: searchParams.get("end_date"),
    human_verified: searchParams.get("human_verified"),
    over_due: searchParams.get("over_due"),
    assigned_at_order: searchParams.get("assigned_at_order") || "desc"
  };
  const { data, isLoading, isError } = useGetInvoiceAssignment(payload);
  const navigate = useNavigate();
  const updateParams = useUpdateParams();
  useEffect(() => {
    setTotalPages(data?.total_pages);
  }, [data]);
  if (isLoading) {
    return <Skeleton active />;
  }

  if (isError) {
    return <div>Error loading assignments.</div>;
  }

  function calculateTimeDifference(dueDate) {
    const now = new Date();
    const timeDiff = dueDate - now;

    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    if (timeDiff <= 0) {
      return `Due  ${hours}h ${minutes}m ago`;
    }
    return `${hours}h ${minutes}m`;
  }

  return (
    <div className="p-5">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Vendor Name</th>
            <th>Restaurant Name</th>
            <th>Assigned To</th>
            <th>
              Assigned At
              {assigned_at_order == "asc" ? (
                <ArrowDown
                  className="mx-1 cursor-pointer"
                  onClick={() => updateParams({ assigned_at_order: "desc" })}
                />
              ) : (
                <ArrowUp
                  className="mx-1 cursor-pointer"
                  onClick={() => updateParams({ assigned_at_order: "asc" })}
                />
              )}
            </th>
            <th>Last Action</th>
            <th>Balance Type</th>
            <th>Time Remaining</th>
            <th>Human Verified</th>
            <th>Schedular</th>
            <th>Rejected</th>
            <th>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.length > 0 &&
            data?.data?.map((assignment) => {
              const verificationDueDate = new Date(
                assignment?.verification_due_at
              );
              const timeRemaining =
                calculateTimeDifference(verificationDueDate);

              return (
                <tr key={assignment?.document?.document_uuid}>
                  <td
                    style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {assignment?.document?.vendor?.vendor_name}
                    {assignment?.document?.vendor?.human_verified && (
                      <PatchCheckFill fill="blue" className="mx-2" />
                    )}
                  </td>
                  <td
                    style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {assignment?.document?.restaurant?.restaurant_name}
                  </td>
                  <td
                   style={{
                    textTransform: "capitalize",
                    background:
                    (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                      assignment?.document?.rejected ||
                      assignment?.document?.archive_status ||
                      assignment?.document?.other_type_document) ? "transparent"
                      : timeRemaining?.includes("Due") && "#f78879"
                  }}
                  >
                    {assignment?.assigned_to?.username}
                  </td>
                  <td
                    style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {new Date(assignment?.assigned_at)?.toLocaleString()}
                  </td>
                  <td
                     style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {assignment?.document?.last_action}
                  </td>
                  <td
                     style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {assignment?.document?.balance_type}
                  </td>
                  <td
                  style={{
                    textTransform: "capitalize",
                    background:
                    (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                      assignment?.document?.rejected ||
                      assignment?.document?.archive_status ||
                      assignment?.document?.other_type_document) ? "transparent"
                      : timeRemaining?.includes("Due") &&"#f78879"
                  }}
                  >
                    <div
                      style={{
                        fontWeight: timeRemaining?.includes("Due") && 400,
                        background:
                        (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                          assignment?.document?.rejected ||
                          assignment?.document?.archive_status ||
                          assignment?.document?.other_type_document) ? "transparent"
                          : timeRemaining?.includes("Due") &&"#f78879"
                      }}
                    >
                       {assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                      assignment?.document?.rejected ||
                      assignment?.document?.archived ||
                      assignment?.document?.other_type_document
                        ? "Completed"
                        : timeRemaining?.split("-").join("")}
                    </div>
                  </td>
                  <td
                     style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {assignment?.document?.human_verified ? "Yes" : "No"}
                  </td>
                  <td
                    style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {assignment?.scheduler}
                  </td>
                  <td
                    style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    {assignment?.document?.rejected ? "Yes" : "No"}
                  </td>
                  <td
                    style={{
                      textTransform: "capitalize",
                      background:
                      (assignment?.document?.human_verified ||assignment?.document?.rejected_by_clickbacon||
                        assignment?.document?.rejected ||
                        assignment?.document?.archive_status ||
                        assignment?.document?.other_type_document) ? "transparent"
                        : timeRemaining?.includes("Due") &&"#f78879"
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate(
                          `/invoice-details?document_uuid=${assignment?.document?.document_uuid}`
                        );
                      }}
                      size="sm"
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <HomePagination />
    </div>
  );
}
