import { queryClient } from "lib/react-query";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, FormControl, Spinner } from "react-bootstrap";
import { Trash3Fill } from "react-bootstrap-icons";

import { PdfViewer } from "modules/InvoiceDetails/components/PdfViewer";
import { useParams, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useUpdateVendorItemMaster } from "../api";
import { invoiceDetailStore } from "store/invoiceDetailsStore";

export default function FastItemMasterVerificationTable({
  data,
  additionalData,
  payload,
  rowToEdit,
  setRowToEdit,
  editableCell,
  setEditableCell,
  pdfsData
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [approveId, setApproveId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [searchParams] = useSearchParams();
  const { mutate: updateVendorItemMaster } = useUpdateVendorItemMaster();
  let page = searchParams.get("page_Index") || 1;
  let document_uuid = searchParams.get("document_uuid") || "";
  let category_choices = additionalData?.data?.category_choices;

  const { vendorId: vendor_id } = useParams();
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const handleEditClick = (e, id, row) => {
    setEditableCell(id);
    setRowToEdit(row);
  };
  const { setBoundingBox, setBoundingBoxes, bounding_boxes, setHighlightAll,setHighlightRow } =
    invoiceDetailStore();
  const setCachedData = (key, val, index = 0) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { items } = copyObj?.data;
    items[index][key] = val;
    queryClient.setQueryData(["vendor-item-master", payload], copyObj);
  };

  const handleSetBoundingBoxes = (col_key, v) => {
    if(document_uuid){
      setBoundingBoxes([])

    }
    };
  useEffect(() => {
    if(document_uuid){
      setBoundingBoxes([]);

    }
    if (loaded) {
      let line_items = Object.values(
        pdfsData?.data?.map(
          ({
            document_link,
            document_source,
            document_uuid,
            is_bounding_box_exist,
            restaurant_id,
            document_reference,
            ...rest
          }) => rest
        )
      );
     
      let pushed = [];
      Object.values(line_items[0]?.line_item)?.map((it) =>
        pushed.push({ box: it?.bounding_boxes, page_index: it?.page_index})
      );

      setBoundingBoxes(pushed);
    
      setBoundingBox({...pushed[1]})
      setHighlightRow(true)
      setHighlightAll(false)
    }
    // setHighlightAll(true);
  }, [loaded,document_uuid]);

  return (
    <>
      <div
        className="sticky-top bg-white"
        style={{ position: "sticky", top: 70 }}
      >
        <div className="w-100 justify-content-center align-items-center d-flex ">
          <div
            style={{
              maxHeight: "480px",
              overflow: "auto",
              width: "1200px",
              resize: "vertical",
              padding: "10px",
              border: "1px solid orange",
              borderRadius: 10,
              marginBottom: 20,
              marginTop: 4,
              overflow: "hidden"
            }}
          >
            {pdfsData?.data && (
              <PdfViewer
                setLoaded={setLoaded}
                pdfUrls={pdfsData?.data}
                showViewInvoiceItems={true}
                showIsBounding={true}
                showBorder={false}
              ></PdfViewer>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          overflowX: "auto",
          display: "flex",
          height: `${isOpen ? "300px" : ""}`
        }}
      >
        <div
          className={`table-responsive  ${
            viewInvoice ? "col-md-6 mx-2" : "col-md-12"
          }`}
        >
          <table
            className="table table-striped table-hover table-bordered shadow-sm table-responsive"
            style={{
              minHeight: "250px"
            }}
          >
            <thead>
              <tr>
                {data?.data?.required_columns?.map((col) => {
                  return (
                    <th key={col} style={{ textTransform: "capitalize" }}>
                      {col.split("_").join(" ")}
                    </th>
                  );
                })}

                <th style={{ textAlign: "center" }}>Category Review</th>
                <th style={{ textAlign: "center" }}>Approve</th>

                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.items?.map((row, mainIndex) => (
                <React.Fragment key={row.item_uuid}>
                  <tr>
                    {data?.data?.required_columns?.map((col) => {
                      return (
                        <td
                          onMouseEnter={() => {
                            handleSetBoundingBoxes(col, row[col]);
                          }}
                          onClick={(e) =>
                            handleEditClick(e, row.item_uuid, row)
                          }
                          key={col}
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* { editableCell==null &&  col == "category" ? "" : row[col]} */}
                          {editableCell == null ? (
                            <>
                              {col == "category" ? (
                                <>{row["category"]?.name}</>
                              ) : (
                                row[col]
                              )}
                            </>
                          ) : (
                            <>
                              {col == "category" ? (
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    style={{
                                      background: "none",
                                      color: "black",
                                      minWidth: "200px",
                                      border: "none"
                                    }}
                                  >
                                    {row["category"]?.name || "Select Category"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: "200px",
                                      overflow: "auto"
                                    }}
                                  >
                                    <FormControl
                                      style={{
                                        position: "sticky",
                                        top: "-10px"
                                      }}
                                      value={categorySearchTerm}
                                      placeholder="Search category name"
                                      onChange={(e) =>
                                        setCategorySearchTerm(e.target.value)
                                      }
                                    />
                                    {category_choices
                                      ?.filter((it) =>
                                        it?.name
                                          ?.toLowerCase()
                                          ?.includes(
                                            categorySearchTerm?.toLowerCase()
                                          )
                                      )
                                      ?.map(({ category_id, name }, index) => {
                                        return (
                                          <Dropdown.Item
                                            key={category_id}
                                            onClick={() =>
                                              setCachedData(
                                                "category",
                                                { category_id, name },
                                                mainIndex
                                              )
                                            }
                                          >
                                            {name}
                                          </Dropdown.Item>
                                        );
                                      })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <textarea
                                 className="w-100 border-0 rounded-1"
                                  value={row[col]}
                                  onChange={(e) =>
                                    setCachedData(
                                      col,
                                      e.target.value,
                                      mainIndex
                                    )
                                  }
                                />
                              )}
                            </>
                          )}
                        </td>
                      );
                    })}

                    <td>
                      <>
                        {" "}
                        <Form.Check
                          checked={data?.data?.[`human_verified`]}
                          type="switch"
                          onChange={(e) => {
                            setCachedData(
                              "category_review_required",
                              e.target.checked,
                              mainIndex
                            );
                          }}
                        />
                      </>
                    </td>
                    <td
                      style={{
                        textAlign: "center"
                      }}
                    >
                      {false && approveId == row.item_uuid ? (
                        <Spinner
                          size="sm"
                          animation="border"
                          variant="primary"
                        />
                      ) : row["human_verified"] === true ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="green"
                          className="bi bi-check-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="blue"
                          className="bi bi-hand-thumbs-up-fill"
                          viewBox="0 0 16 16"
                          onClick={() => {
                            setApproveId(row.item_uuid);
                            // handleApprove(row);
                            setCachedData("human_verified", true, mainIndex);
                            updateVendorItemMaster(
                              {
                                item_uuid: row?.item_uuid,
                                data: { human_verified: true }
                              },
                              {}
                            );
                          }}
                          style={{
                            cursor: "pointer"
                          }}
                        >
                          <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                        </svg>
                      )}
                    </td>

                    <td>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          // setItemToDelete(row);
                          // setShowDeleteModal(true);
                        }}
                      >
                        <Trash3Fill />
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <ToastContainer />
      </div>
      {/* <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Item Master</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {itemToDelete?.item_description}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              handleDeleteItemMaster(
                itemToDelete.item_uuid,
                itemToDelete.branch_id,
                true
              );
              setShowDeleteModal(false);
            }}
          >
            Soft Delete
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              handleDeleteItemMaster(
                itemToDelete.item_uuid,
                itemToDelete.branch_id,
                false
              );
              setShowDeleteModal(false);
            }}
            disabled={true}
          >
            Hard Delete
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
