import { Spinner } from "components/common/Spinner";
import { NavHeader } from "components/layout/NavHeader";
import {
  Accordion,
  Dropdown,
  FormControl,
  ListGroup,
  ProgressBar
} from "react-bootstrap";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  PlusCircleFill,
  Trash3Fill
} from "react-bootstrap-icons";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header";
import {
  useBulkUpdateVendorItems,
  useGetAdditionalData,
  useGetItemMasterPdfs,
  useGetVendorDetails,
  useGetVendorItemMaster,
  useUpdateVendorDetails,
  useUpdateVendorItemMaster
} from "./api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { queryClient } from "lib/react-query";
import { useEffect, useState } from "react";
import FastItemMasterVerificationTable from "./components/FastItemVerificationTable";
import useUpdateParams from "hooks/useUpdateParams";
export default function FastItemMasterVerification() {
  const { vendorId: vendor_id } = useParams();
  const [headerDisplayNameSearchTerm, setHeaderDisplayNameSearchTerm] =
    useState("");
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();
  const updateParams = useUpdateParams();
  const [searchParams] = useSearchParams();
  const { mutate: updateVendorDetails } = useUpdateVendorDetails();
  const { data, isLoading } = useGetVendorDetails(vendor_id);
  let page = searchParams.get("page_Index") || 1;
  let document_uuid = searchParams.get("document_uuid") || "";
  let page_size = searchParams.get("page_size") || 1;

  const { data: additionalData, isLoading: loadingAdditionalData } =
    useGetAdditionalData();

  //  Functions for Accordions

  const filteredColumns =
    additionalData?.data?.processed_table_header_candidates?.filter(
      (item) => item?.is_required_for_item_master
    );
  let headerDisplayNameOptions =
    additionalData?.data?.processed_table_header_candidates
      ?.map(
        ({ is_default_in_item_master, is_required_for_item_master, ...rest }) =>
          rest
      )
      ?.map(({ column_name }) => column_name);
  const handleInputValueChange = (key, value, item) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { invoice_header_names_mapping } = copyObj?.data;
    invoice_header_names_mapping[item][key] = value;
    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };

  const handleHeaderDisplayNameSelect = (item, value, index) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { invoice_header_names_mapping } = copyObj?.data;

    if (item in invoice_header_names_mapping) {
      // Create a new object to maintain order
      const newMapping = {};
      // Copy existing keys to the new object
      Object?.keys(invoice_header_names_mapping).forEach((key) => {
        if (key === item) {
          newMapping[value] = invoice_header_names_mapping[key]; // Add the new key
        } else {
          newMapping[key] = invoice_header_names_mapping[key]; // Copy other keys as is
        }
      });
      copyObj.data.invoice_header_names_mapping = newMapping;
    }

    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };
  const deleteRow = (item) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { invoice_header_names_mapping } = copyObj?.data;

    if (item in invoice_header_names_mapping) {
      delete invoice_header_names_mapping[item];
    }
    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };

  const handleCheckboxChange = (column_name, value) => {
    let copyObj = JSON.parse(JSON.stringify(data));
    let { required_invoice_columns_for_item_master } = copyObj?.data;

    if (!required_invoice_columns_for_item_master) {
      required_invoice_columns_for_item_master = [];
    }
    const columnNameLower = column_name.toLowerCase();

    if (value === false) {
      //    Remove the column name if it exists
      required_invoice_columns_for_item_master =
        required_invoice_columns_for_item_master.filter(
          (item) => item.toLowerCase() !== columnNameLower
        );
    } else {
      //   Add the column name if it doesn't exist
      if (!required_invoice_columns_for_item_master.includes(columnNameLower)) {
        required_invoice_columns_for_item_master.push(columnNameLower);
      }
    }

    // Update the copy of the data
    copyObj.data.required_invoice_columns_for_item_master =
      required_invoice_columns_for_item_master;

    // Update the query data with the modified copy
    queryClient.setQueryData(["vendor-details", vendor_id], copyObj);
  };
  let payload = {
    vendor_id,
    human_verified: "",
    category_review_required: "",
    verified_by: "",
    item_code: "",
    item_description: "",
    page,
    document_uuid,
    page_size,
    is_bounding_box_present:"true"
  };
  const { data: itemsData, isLoading: loadingData } =
    useGetVendorItemMaster(payload);
  const { data: pdfsData, isLoading: loadingPdfs } = useGetItemMasterPdfs(
    itemsData?.data?.items?.[0]?.["item_uuid"]
  );
  const { mutate: updateVendorItemMaster } = useUpdateVendorItemMaster();
  const { mutate: bulkUpdateVendorItems } = useBulkUpdateVendorItems();

  const [editableCell, setEditableCell] = useState(null);
  const [saving, setSaving] = useState(false);
  const totalPages = itemsData?.total_pages;
  const isFinalPage = itemsData?.is_final_page;
  const [rowToEdit, setRowToEdit] = useState(null);
  const handleSaveLineItem = () => {
    if (itemsData?.data?.items?.length == 1) {
      setSaving(true);

      updateVendorItemMaster(
        {
          item_uuid: itemsData?.data?.items?.[0]["item_uuid"],
          data: itemsData?.data?.items?.[0]
        },
        {
          onSuccess: () => setSaving(false),
          onError: () => setSaving(false)
        }
      );
    } else {
      setSaving(true);

      bulkUpdateVendorItems(
        { items: itemsData?.data?.items },
        {
          onSuccess: () => setSaving(false),
          onError: () => setSaving(false)
        }
      );
    }
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft" && page > 1) {
        // Go to the previous page
        updateParams({ ["page_Index"]: Number(page) - 1 });
      } else if (e.key === "ArrowRight" && page < totalPages) {
        // Go to the next page
        updateParams({ ["page_Index"]: Number(page) + 1 });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [page, totalPages, updateParams]);

  return (
    <>
      <NavHeader view="FIV" />
      <ToastContainer />
      <Header
        className="sticky-top"
        text={`Fast Item Verification`}
        children={
          <div className="d-flex justify-content-end">
            <span className="mr-3">
              Total: {itemsData?.data?.total_item_count || 0}
            </span>
            <ProgressBar
              now={itemsData?.data?.verified_item_count * 100 || 0}
              max={itemsData?.data?.total_item_count * 100 || 0}
              label={`${
                !loadingAdditionalData &&
                `${itemsData?.data?.verified_item_count}/${itemsData?.data?.total_item_count}`
              }`}
              variant="success"
              style={{
                backgroundColor: "white",
                width: "300px",
                marginTop: "5px",
                marginLeft: "10px"
              }}
            />
          </div>
        }
      />
      {loadingData && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}

      {!loadingData && (
        <div className="mx-5 my-4 bg-white ">
          <div style={{ width: "100%" }} className=" z-3">
            <FastItemMasterVerificationTable
              data={itemsData}
              additionalData={additionalData}
              payload={payload}
              editableCell={editableCell}
              setEditableCell={setEditableCell}
              rowToEdit={rowToEdit}
              setRowToEdit={setRowToEdit}
              pdfsData={pdfsData}
            />
          </div>
          <div className="d-flex justify-content-between ">
            <div
              className="mr-3 text-primary"
              style={{
                textDecoration: "underline",
                cursor: "pointer"
              }}
              onClick={() => {
                navigate(`/vendor-consolidation-v2/${vendor_id}`);
              }}
            >
              Back to Vendor
            </div>
            <div className="d-flex gap-3">
              <span className="mr-3 text-muted">
                Page: {page} of {totalPages}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary mx-3"
              disabled={page == 1}
              onClick={() => {
                if (page == 1) {
                  return;
                }
                updateParams({ page_Index: Number(page) - 1 });
              }}
            >
              <ArrowLeftCircle />
            </button>
            <button
              className="btn btn-primary mx-3"
              onClick={() => {
                if (page === totalPages) {
                  return;
                }

                if (!isFinalPage) {
                  updateParams({ page_Index: Number(page) + 1 });
                }
              }}
              // disabled={
              //   currentItemIndex === itemList?.length - 1 &&
              //   pageIndex === totalPages
              // }
              // disabled={pageIndex === totalPages}
            >
              <ArrowRightCircle />
            </button>

            <button
              disabled={saving}
              className="btn btn-primary mx-3"
              onClick={() => {
                // handleEditableCellBlur();
                handleSaveLineItem();
              }}
            >
              {saving ? "Saving.." : "Save"}
            </button>
            {rowToEdit && (
              <button
                className="btn btn-danger mx-3"
                onClick={() => {
                  setEditableCell(null);
                  setRowToEdit(null);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      )}

      <div className="mx-5">
        <Accordion className="my-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Columns and Headers</Accordion.Header>
            <Accordion.Body>
              <Accordion defaultActiveKey="0" style={{ marginBottom: 20 }}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Invoice Header Column Data
                  </Accordion.Header>
                  <Accordion.Body>
                    <p
                      className="w-100"
                      style={{
                        borderBottom: "1px solid #7D7C7C",
                        marginTop: 15,
                        paddingBottom: 10,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      All Invoice Columns
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(5, 1fr)",
                        gap: "10px",
                        marginBottom: 10
                      }}
                    >
                      {filteredColumns?.map((item) => (
                        <div
                          key={item?.column_name}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            height: 40,
                            padding: "0 10px"
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={data?.data?.required_invoice_columns_for_item_master?.some(
                              (it) =>
                                it?.toLowerCase() ===
                                item?.column_name?.toLowerCase()
                            )}
                            onChange={(e) => {
                              handleCheckboxChange(
                                item?.column_name,
                                e.target.checked
                              );
                            }}
                          />
                          <p style={{ paddingLeft: 5, margin: 0 }}>
                            {" "}
                            {item?.column_name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" style={{ marginBottom: 20 }}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Invoice Header Mapping</Accordion.Header>
                  <Accordion.Body>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <div className="border-white rounded shadow-sm p-3">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Header Display Name</th>
                                <th>Actual Header Name</th>
                                <th>Actual Header Position</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.invoice_header_names_mapping &&
                                Object?.keys(
                                  data?.data?.invoice_header_names_mapping
                                )?.map((item) => {
                                  return (
                                    <tr key={item}>
                                      <td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center"
                                        }}
                                      >
                                        <Dropdown
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            width: "80%"
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              width: "100%",
                                              alignContent: "start",
                                              justifyContent: "start",
                                              alignItems: "start",
                                              color: "black",
                                              border: "1px solid #E9EFEC",
                                              background: "white",
                                              display: "flex",
                                              alignItems: "center", // Center items vertically
                                              justifyContent: "flex-start", //,
                                              textTransform: "capitalize"
                                            }}
                                          >
                                            {item == null
                                              ? "Select Header Display Name"
                                              : item}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu
                                            style={{
                                              maxHeight: "200px",
                                              overflow: "auto"
                                            }}
                                          >
                                            <FormControl
                                              value={
                                                headerDisplayNameSearchTerm
                                              }
                                              placeholder="Search"
                                              style={{
                                                position: "sticky",
                                                top: -7
                                              }}
                                              onChange={(e) =>
                                                setHeaderDisplayNameSearchTerm(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            {headerDisplayNameOptions
                                              ?.filter((itm) =>
                                                itm
                                                  ?.toLowerCase()
                                                  .includes(
                                                    headerDisplayNameSearchTerm?.toLowerCase()
                                                  )
                                              )
                                              ?.map((it, ind) => {
                                                return (
                                                  <Dropdown.Item
                                                    key={ind}
                                                    onClick={() =>
                                                      handleHeaderDisplayNameSelect(
                                                        item,
                                                        it
                                                      )
                                                    }
                                                  >
                                                    {it}
                                                  </Dropdown.Item>
                                                );
                                              })}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>

                                      <td>
                                        <FormControl
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              "actual_header_name",
                                              e.target.value,
                                              item
                                            )
                                          }
                                          value={
                                            data.data
                                              .invoice_header_names_mapping[
                                              item
                                            ]?.actual_header_name
                                          }
                                        />
                                      </td>
                                      <td>
                                        <FormControl
                                          type="number"
                                          onChange={(e) =>
                                            handleInputValueChange(
                                              "actual_header_position",
                                              e.target.value,
                                              item
                                            )
                                          }
                                          value={
                                            data.data
                                              .invoice_header_names_mapping[
                                              item
                                            ]?.actual_header_position
                                          }
                                        />
                                      </td>
                                      <td style={{ justifyContent: "center" }}>
                                        <button
                                          onClick={() => deleteRow(item)}
                                          type=""
                                          style={{
                                            border: "none",
                                            background: "red",
                                            borderRadius: 5
                                          }}
                                        >
                                          <Trash3Fill
                                            color="white"
                                            style={{
                                              margin: 6,
                                              marginBottom: 8
                                            }}
                                          />
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="w-100 d-flex justify-content-end mt-2">
                <div
                  className="mb-3"
                  style={{
                    width: "10%",
                    alignContent: "end",
                    justifyContent: "end"
                  }}
                >
                  <button
                    className="btn btn-success"
                    style={{ width: "100%" }}
                    disabled={updating}
                    onClick={() => {
                      setUpdating(true);
                      updateVendorDetails(
                        { vendor_id, data: data?.data },
                        {
                          onSuccess: () => {
                            setUpdating(false);
                            queryClient.invalidateQueries({
                              queryKey: ["vendor-details"]
                            });
                            queryClient.invalidateQueries({
                              queryKey: ["additional-data"]
                            });
                          },
                          onError: () => {
                            setUpdating(false);
                          }
                        }
                      );
                    }}
                  >
                    {updating ? "Saving...." : "Save"}
                  </button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      {/* <Modal
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Similar Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            style={{
              fontWeight: "bold"
            }}
          >
            Searched Item Code: {masterItem?.item_code}{" "}
          </p>
          <p
            style={{
              fontWeight: "bold"
            }}
          >
            Searched Item Description: {masterItem?.item_description}{" "}
          </p>
          {similarItems?.length > 0 ? (
            <>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left"
                      }}
                    >
                      Category
                    </th>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left"
                      }}
                    >
                      Item Code
                    </th>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left"
                      }}
                    >
                      Item Description
                    </th>
                    <th
                      className="text-center"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "left"
                      }}
                    >
                      Select
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {similarItems?.map(
                    ({ item_code, category, item_description, item_uuid }) => {
                      return (
                        <tr>
                          <td
                            className="text-center"
                            style={{
                              border: "1px solid black",
                              padding: "8px"
                            }}
                          >
                            {category}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "1px solid black",
                              padding: "8px"
                            }}
                          >
                            {item_code}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "1px solid black",
                              padding: "8px"
                            }}
                          >
                            {item_description}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px"
                            }}
                            className="text-center"
                          >
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              checked={selectedItems.includes(item_uuid)}
                              onChange={(e) => {
                                //
                                if (e.target.checked) {
                                  setSelectedItems((prev) => [
                                    ...prev,
                                    item_uuid
                                  ]);
                                }
                                if (!e.target.checked) {
                                  setSelectedItems(
                                    selectedItems.filter(
                                      (item) => item != item_uuid
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <p>No Similar Item Available</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center ">
          <Button
            type="submit"
            form="add_issue_form"
            variant="success"
            onClick={() => {
              mergeHanler();
            }}
            className="mx-2 px-5 align-items-center text-center"
          >
            Merge
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
