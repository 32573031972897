import { Spinner } from "components/common/Spinner";
import { Spinner as Loader } from "react-bootstrap";
import { NavHeader } from "components/layout/NavHeader";
import useUpdateParams from "hooks/useUpdateParams";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal, ProgressBar } from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  Download,
  PlusCircleFill,
  Search
} from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  useAddVendor,
  useDeleteVendor,
  useDownloadVendorData,
  useGetAllUsers,
  useGetVendorListing
} from "./api";
import * as XLSX from "xlsx";
import { JsonToExcel } from "react-json-to-excel";
import Header from "./components/Header";
import VendorTable from "./components/VendorTable";
import CustomDropdown from "components/CustomDropdown";
import { DateRange } from "react-date-range";
import useFilterStore from "store/persistentFilterStore";
import { formatDate } from "components/layout/FilterDropdown";
import { queryClient } from "lib/react-query";
export default function Home() {
  const [searchParams] = useSearchParams();
  const updateParams = useUpdateParams();
  let human_verified = searchParams.get("human_verified") || "all";
  let vendor_category = searchParams.get("vendor_category") || "";
  let page = searchParams.get("page") || 1;
  let page_size = searchParams.get("page_size") || 10;
  let vendor_name_search = searchParams.get("vendor_name_search") || "";
  let start_date = searchParams.get("start_date") || "";
  let end_date = searchParams.get("end_date") || "";
  const { mutate: downloadData, isPending: downloading } =
    useDownloadVendorData();

  const [searchTerm, setSearchTerm] = useState(vendor_name_search);
  const [page_index, setPageIndex] = useState(page);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  let verified_by = searchParams.get("verified_by") || "all";
  let branch_count_order = searchParams.get("branch_count_order") || "all";
  let verified_branch_count_order =
    searchParams.get("verified_branch_count_order") || "all";
  let verified_item_count_order =
    searchParams.get("verified_item_count_order") || "all";
  let item_count_order = searchParams.get("item_count_order") || "all";
  let document_count_order = searchParams.get("document_count_order") || "all";
  let created_date_order = searchParams.get("created_date_order") || "all";
  let recent_addition_date_order =
    searchParams.get("recent_addition_date_order") || "all";
  let archived_status = searchParams.get("archived_status") || "all";

  const { data: userData, isLoading: userDataLoading } = useGetAllUsers();
  const { data, isLoading } = useGetVendorListing({
    human_verified,
    vendor_category,
    page,
    page_size,
    vendor_name_search,
    verified_by,
    start_date,
    end_date,
    branch_count_order,
    verified_branch_count_order,
    verified_item_count_order,
    item_count_order,
    document_count_order,
    created_date_order,
    recent_addition_date_order,
    archived_status
  });

  const { mutate: deleteVendor, isPending: deletingVendor } = useDeleteVendor();
  const { mutate: addVendor, isPending: addingVendor } = useAddVendor();
  const handleDeleteVendor = (vendor_id) => {
    setIsDeleting(true);
    deleteVendor(vendor_id, {
      onSettled: () => {
        setIsDeleting(false);
        queryClient.invalidateQueries({ querykey: ["vendor-listing"] });
      }
    });
  };
  const vendors = data?.data?.vendors;
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });

  const total_pages = data?.total_pages;
  const { setFilters } = useFilterStore();
  const clearFilters = () => {
    updateParams({
      human_verified: undefined,
      sort_order: undefined,
      rerun_status: undefined,
      clickbacon_status: undefined,
      invoice_type: undefined,
      detected: undefined,
      auto_accepted: undefined,
      start_date: undefined,
      end_date: undefined
    });
    setFilters({
      human_verified: undefined,
      sort_order: undefined,
      rerun_status: undefined,
      clickbacon_status: undefined,
      invoice_type: undefined,
      detected: undefined,
      auto_accepted: undefined,
      start_date: undefined,
      end_date: undefined
    });
    setDateRange({ startDate: null, endDate: null });
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft" && page_index > 1) {
        // Go to the previous page
        updateParams({ page: Number(page_index) - 1 });
        setPageIndex(Number(page_index) - 1);
      } else if (e.key === "ArrowRight" && page_index < total_pages) {
        // Go to the next page
        updateParams({ page: Number(page_index) + 1 });
        setPageIndex(Number(page_index) + 1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [page_index, total_pages, updateParams]);

  useEffect(() => {
    setPageIndex(page);
  }, [page]);

  const [vendorsData, setVendorsData] = useState([]);

  const handleDownload = () => {
    downloadData(
      {
        human_verified,
        vendor_category,
        vendor_name_search,
        verified_by,
        start_date,
        end_date,
        branch_count_order,
        verified_branch_count_order,
        verified_item_count_order,
        item_count_order,
        document_count_order,
        created_date_order,
        recent_addition_date_order,
        archived_status
      },
      {
        onSuccess: (fetchedData) => {
          // Assuming fetchedData.data.vendors is an array
          const data = fetchedData?.data?.vendors;

          if (data && Array.isArray(data)) {
            // Convert JSON data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(data);

            // Create a new workbook
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Vendors");

            // Export the workbook to an Excel file
            XLSX.writeFile(workbook, "vendors_data.xlsx");
          } else {
            console.error("Invalid data format for Excel download.");
          }
        }
      }
    );
  };

  return (
    <>
      <NavHeader view="Vendors" />
      <ToastContainer />
      <Header
        text={"Vendors"}
        children={
          <div className="d-flex justify-content-end">
            <span
              className="mr-3"
              style={{ display: "flex", flexDirection: "row" }}
            >
              Total: {!isLoading ? data?.data?.total_vendor_count : 0}{" "}
            </span>
            <ProgressBar
              now={data?.data?.verified_vendor_count}
              max={data?.data?.total_vendor_count}
              label={`${isLoading ? 0 : data?.data?.verified_vendor_count}`}
              variant="success"
              style={{
                backgroundColor: "white",
                width: "300px",
                marginTop: "5px",
                marginLeft: "10px"
              }}
            />
          </div>
        }
      />
      <div className="row mt-3 mx-5">
        <div className="col-md-6 d-flex">
          <input
            type="text"
            value={searchTerm}
            className="form-control"
            placeholder="Search by vendor name"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateParams({ vendor_name_search: searchTerm, page: 1 });
              }
            }}
            onChange={(e) => {
              if (e.target.value == "") {
                updateParams({ vendor_name_search: undefined, page: 1 });
                setSearchTerm("");
              } else {
                setSearchTerm(e.target.value);
              }
            }}
          />
          <button
            className="btn btn-light"
            onClick={() => {
              updateParams({ vendor_name_search: searchTerm, page: 1 });
              // setSearchTerm("");
            }}
          >
            <Search />
          </button>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <PlusCircleFill className="mx-2" />
            Add Vendor
          </button>
          <button
            disabled={downloading}
            className="btn btn-primary"
            onClick={handleDownload}
          >
            <Download className="mx-2" />
            Download
          </button>
          {vendorsData?.length > 0 && (
            <JsonToExcel
              title="Download Excel"
              data={vendorsData}
              fileName="data"
              btnClassName="excel-download-btn"
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end mx-5 mt-2 mb-0">
        <Dropdown className="mx-2">
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{ textTransform: "capitalize" }}
          >
            {recent_addition_date_order !== "all"
              ? recent_addition_date_order == "asc"
                ? "Ascending"
                : recent_addition_date_order == "desc"
                  ? "Descending"
                  : ""
              : "Sort by Recent Additions"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              value={human_verified}
              onClick={() => {
                updateParams({ recent_addition_date_order: "asc", page: 1 });
              }}
            >
              Ascending
            </Dropdown.Item>
            <Dropdown.Item
              value={human_verified}
              onClick={() => {
                updateParams({ recent_addition_date_order: "desc", page: 1 });
              }}
            >
              Descending
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                updateParams({ recent_addition_date_order: "all", page: 1 });
              }}
            >
              Clear
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="mx-2">
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{ textTransform: "capitalize" }}
          >
            {human_verified !== "all" ? human_verified : "Human Verified"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              value={human_verified}
              onClick={() => {
                updateParams({ human_verified: true, page: 1 });
              }}
            >
              Verified
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                updateParams({ human_verified: false, page: 1 });
              }}
            >
              Not Verified
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                updateParams({ human_verified: "all", page: 1 });
              }}
            >
              Clear
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="mx-2">
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{ textTransform: "capitalize" }}
          >
            {archived_status !== "all" ? archived_status : "Archive Status"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              value={archived_status}
              onClick={() => {
                updateParams({ archived_status: true, page: 1 });
              }}
            >
              True
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                updateParams({ archived_status: false, page: 1 });
              }}
            >
              False
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                updateParams({ archived_status: "all", page: 1 });
              }}
            >
              Clear
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {!userDataLoading && (
          <CustomDropdown
            data={[{ id: undefined, username: "None" }, ...userData?.data]}
            Objectkey={"id"}
            displayKey={"username"}
            className="w-auto"
            maxWidth="180px"
            minWidth="180px"
            value={
              userData?.data?.find((user) => user?.id === verified_by)
                ?.username || "Verified By"
            }
            onSelect={(v) => {
              updateParams({ verified_by: v, page: 1 });
            }}
          />
        )}

        <Dropdown className="mx-2">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {vendor_category == "" ? "Vendor Category" : vendor_category}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {["NA", "Normal Invoice", "Liquor Invoice", "Summary Invoice"].map(
              (category) => (
                <Dropdown.Item
                  onClick={() => {
                    updateParams({
                      vendor_category: category == "NA" ? "" : category,
                      page: 1
                    });
                    setPageIndex(1);
                  }}
                >
                  {category === null ? "None" : category}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="light">Date</Dropdown.Toggle>
          <Dropdown.Menu>
            <span
              className="text-muted"
              style={{
                fontSize: "12px",
                marginLeft: "0px", // Adjusted spacing to keep it aligned nicely with the label
                textDecoration: "underline",
                cursor: "pointer",
                display: "flex",
                justifyContent: "end",
                paddingRight: "20px"
              }}
              onClick={() => {
                clearFilters();
              }}
            >
              Reset
            </span>
            <DateRange
              id="dateRange"
              editableDateInputs={true}
              onChange={(item) => {
                const startDate = formatDate(item.selection.startDate);
                const endDate = formatDate(item.selection.endDate);

                // Update the date range in the state and the params
                setDateRange({ startDate, endDate });
                updateParams({
                  start_date: startDate,
                  end_date: endDate,
                  page: 1
                });
                setFilters({
                  start_date: startDate,
                  end_date: endDate,
                  page: 1
                });
              }}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: dateRange.startDate
                    ? new Date(dateRange.startDate)
                    : new Date(),
                  endDate: dateRange.endDate
                    ? new Date(dateRange.endDate)
                    : new Date(),
                  key: "selection"
                }
              ]}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {isLoading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <VendorTable
            data={vendors}
            handleDeleteVendor={handleDeleteVendor}
            isDeleting={isDeleting}
          />
          <div className="row">
            <div className="mt-4 mb-2">
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={page_index == 1 || total_pages == 1}
                onClick={() => {
                  if (page_index > 1) {
                    setPageIndex((prev) => Number(prev) - 1);
                    updateParams({ page: Number(page_index) - 1 });
                  }
                }}
              >
                <ArrowLeftCircleFill size={40} />
              </button>
              <span className="my-4 mx-2">
                <input
                  value={page_index}
                  onChange={(e) => {
                    setPageIndex(e.target.value);
                  }}
                  onBlur={() => {
                    if (page_index >= total_pages) {
                      setPageIndex(total_pages);
                      updateParams({ page: total_pages });
                    } else {
                      updateParams({ page: page_index });
                    }
                  }}
                  className="btn btn-secondary"
                  style={{ width: "7ch" }}
                />
                <span className="my-4">
                  {" "}
                  <strong>/</strong>{" "}
                  <input
                    disabled
                    value={`${data?.total_pages}`}
                    className="btn btn-secondary"
                    style={{ width: "7ch", cursor: "default" }}
                  />
                </span>
              </span>
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={0}
                onClick={() => {
                  if (page_index < total_pages) {
                    setPageIndex((prev) => Number(prev) + 1);
                    updateParams({ page: Number(page_index) + 1 });
                  }
                }}
              >
                <ArrowRightCircleFill size={40} />
              </button>
            </div>
          </div>
        </>
      )}

      <Modal
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Vendor Name</label>
            <input
              type="text"
              className="form-control"
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              setIsAdding(true);
              addVendor(vendorName, {
                onSuccess: (data) => {
                  setVendorName("");
                  setIsAdding(false);
                  setShowModal(false);
                  toast.success(data?.message, {
                    autoClose: 2000
                  });
                },
                onSettled: () => {
                  setIsAdding(false);
                }
              });
            }}
            disabled={addingVendor}
          >
            {isAdding ? (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5
                }}
              >
                {" "}
                <Loader style={{ height: 20, width: 20 }} />
                Adding...
              </span>
            ) : (
              "Add"
            )}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowModal(false);
              setVendorName("");
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
