import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "config/env";
import { axiosInstance, instance } from "lib/axios";
import { queryClient } from "lib/react-query";
import { toast } from "react-toastify";

// Vendor Consolidation
export const useGetVendorListing = (payload) => {
  return useQuery({
    queryKey: ["vendor-listing", payload],
    queryFn: async () => {
      const {
        vendor_category,
        human_verified,
        page,
        page_size,
        verified_by,
        vendor_name_search,
        start_date,
        end_date,
        branch_count_order,
        verified_branch_count_order,
        verified_item_count_order,
        item_count_order,
        document_count_order,
        created_date_order,
        archived_status,
        recent_addition_date_order
      } = payload;
      const apiUrl = `/api/vendor/?vendor_category=${vendor_category}&human_verified=${human_verified}&page=${page}&page_size=${page_size}&verified_by=${verified_by}&vendor_name_search=${vendor_name_search}&start_date=${start_date}&end_date=${end_date}&item_count_order=${item_count_order}&verified_item_count_order=${verified_item_count_order}&verified_branch_count_order=${verified_branch_count_order}&branch_count_order=${branch_count_order}&document_count_order=${document_count_order}&created_date_order=${created_date_order}&recent_addition_date_order=${recent_addition_date_order}&archived_status=${archived_status}`;
      try {
        //
        const response = await instance.get(apiUrl);
        return response.data;
      } catch (error) {
        console.log(error);
        return error?.response?.data;
      }
    }
  });
};

export const useDownloadVendorData = () => {
  return useMutation({
    mutationFn: async (payload) => {
      const {
        vendor_category,
        human_verified,

        verified_by,
        vendor_name_search,
        start_date,
        end_date,
        branch_count_order,
        verified_branch_count_order,
        verified_item_count_order,
        item_count_order,
        document_count_order,
        created_date_order,
        archived_status,
        recent_addition_date_order
      } = payload;
      const apiUrl = `/api/vendor/?vendor_category=${vendor_category}&human_verified=${human_verified}&verified_by=${verified_by}&vendor_name_search=${vendor_name_search}&start_date=${start_date}&end_date=${end_date}&item_count_order=${item_count_order}&verified_item_count_order=${verified_item_count_order}&verified_branch_count_order=${verified_branch_count_order}&branch_count_order=${branch_count_order}&document_count_order=${document_count_order}&created_date_order=${created_date_order}&recent_addition_date_order=${recent_addition_date_order}&archived_status=${archived_status}`;
      try {
        //
        const response = await instance.get(apiUrl);
        return response.data;
      } catch (error) {
        console.log(error);
        return error?.response?.data;
      }
    }
  });
};

export const useAddVendor = () => {
  return useMutation({
    mutationFn: async (vendor_name) => {
      const response = await instance.post(`/api/vendor/create/`, {
        vendor_name
      });
      return response;
    },

    onSuccess: (data) => {
      toast.success(data?.message);
      queryClient.invalidateQueries({ queryKey: ["vendor-listing"] });
    }
  });
};

export const useDeleteVendor = () => {
  return useMutation({
    mutationFn: async (vendor_id) => {
      const response = await instance.delete(
        `/api/vendor/${vendor_id}/delete/`
      );
      return response;
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 3000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-listing"] });
    }
  });
};

// Vendor Details
export const useGetVendorDetails = (vendor_id) => {
  return useQuery({
    queryKey: ["vendor-details", vendor_id],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor/${vendor_id}/details/`
        );
        return response?.data;
      } catch (error) {
        console.log(`Error in Getting Vendor Details`, error);
        return error?.response?.data;
      }
    }
  });
};
export const useGetVendorDocumentAnalytics = (vendorId) => {
  return useQuery({
    queryKey: ['vendor-document-analytics'],
    queryFn: async () => {
      console.log(vendorId)
      let apiUrl = `/api/vendor/${vendorId}/vendor-document-analytics/`
      try {
        const response = await instance.get(apiUrl);
        return response?.data;
      } catch (error) {
        console.log(`error in fetching additional data`, error);
        return error?.response?.data;
      }
    }
  })
}
export const useGetAdditionalData = () => {
  return useQuery({
    queryKey: ["additional-data"],
    queryFn: async () => {
      const apiUrl = `/api/utils/additional_data/?category_choices=true&processed_table_header_candidates=true&vendor_invoice_document_types=true&vendor_invoice_categories=true&document_types=true`;

      try {
        const response = await instance.get(apiUrl);
        return response?.data;
      } catch (error) {
        console.log(`error in fetching additional data`, error);
        return error?.response?.data;
      }
    }
  });
};

export const useGetInvoiceHeaderMappingException = (vendor_id) => {
  return useQuery({
    queryKey: ["invoice-header-mapping-exceptions", vendor_id],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor/${vendor_id}/invoice-header-exceptions/`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useAssignUnAssignedInvoices = () => {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await instance.post(
        `/api/invoice-balancer-scheduler/assign-unassigned-documents/`,
        { ...payload }
      );
      return response?.data;
    }
  });
};
export const useUpdateInvoiceHeaderMappingException = () => {
  return useMutation({
    mutationFn: async ({ vendor_id, invoice_header_exceptions }) => {
      const response = await instance.post(
        `/api/vendor/${vendor_id}/invoice-header-exceptions/`,
        {
          invoice_header_exceptions
        }
      );
      return response?.data;
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message, {
        autoClose: 3000
      });
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${data?.data?.updated_fields?.length > 0
          ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
          : ``
        }`,
        {
          autoClose: 3000
        }
      );
    }
  });
};
export const useUpdateVendorDetails = () => {
  return useMutation({
    mutationFn: async ({ vendor_id, data }) => {
      const response = await instance.put(`/api/vendor/${vendor_id}/details/`, {
        ...data
      });

      return response.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${data?.data?.updated_fields?.length > 0
          ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
          : ``
        }`,
        {
          autoClose: 2000
        }
      );
    }
  });
};

// Combine Vendors
export const useGetSimilarVendors = ({
  vendor_id,
  similarity,
  mode,
  vendor_name_search
}) => {
  return useQuery({
    queryKey: [
      "similar-vendors",
      vendor_id,
      similarity,
      vendor_name_search,
      mode
    ],
    queryFn: async () => {
      let apiUrl = ``;
      if (similarity) {
        apiUrl = `/api/vendor/${vendor_id}/similar-vendors/?similarity=${similarity}&vendor_name_search=${vendor_name_search}`;
      } else {
        apiUrl = `/api/vendor/${vendor_id}/similar-vendors/?vendor_name_search=${vendor_name_search}&mode=${mode}`;
      }
      try {
        const response = await instance.get(apiUrl);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useCombineVendors = () => {
  return useMutation({
    mutationFn: async ({ vendor_id, vendors_to_combine }) => {
      const response = await instance.post(
        `/api/vendor/${vendor_id}/combine/`,
        { vendors_to_combine }
      );
      return response?.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["similar-vendors"] });
    }
  });
};

// Vendor Branche
export const useGetVendorBranches = (payload) => {
  return useQuery({
    queryKey: ["vendor-branches", payload],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor-branch/${payload?.vendor_id}/?vendor_address=${payload?.vendor_address}&page=${payload?.page}`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useUpdateBranchDetails = () => {
  return useMutation({
    mutationFn: async ({ branch_id, payload }) => {
      const response = await instance.put(
        `/api/vendor-branch/${branch_id}/details/`,
        { ...payload }
      );
      return response?.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${data?.data?.updated_fields?.length > 0
          ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
          : ``
        }`,
        {
          autoClose: 2000
        }
      );
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
      queryClient.invalidateQueries({ queryKey: ["vendor-branch-details"] });
    }
  });
};

export const useCombineVendorBranches = () => {
  return useMutation({
    mutationFn: async ({ branch_id, branches_to_merge }) => {
      const response = await instance.post(
        `/api/vendor-branch/${branch_id}/combine/`,
        {
          branches_to_combine: branches_to_merge
        }
      );
      return response.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
    }
  });
};

export const useMigrateVendorBranch = () => {
  return useMutation({
    mutationFn: async ({ branch_id, new_vendor_id }) => {
      const response = await instance.post(
        `/api/vendor-branch/${branch_id}/migrate/`,
        {
          new_vendor_id
        }
      );
      return response.data;
    },
    onError: (data) => {
      toast.error(data?.message, {
        autoClose: 2000
      });
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
    }
  });
};

export const useDeleteVendorBranch = () => {
  return useMutation({
    mutationFn: async (branch_id) => {
      const response = await instance.delete(
        `/api/vendor-branch/${branch_id}/delete/`
      );
      return response.data;
    },
    onError: (data) => {
      toast.error(data?.message);
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
      queryClient.invalidateQueries({ queryKey: ["vendor-branches"] });
    }
  });
};

export const useGetBranchDetails = (branch_id) => {
  return useQuery({
    queryKey: ["vendor-branch-details", branch_id],
    queryFn: async () => {
      try {
        const response = await instance.get(
          `/api/vendor-branch/${branch_id}/details/`
        );
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

export const useGetVendorNames = () => {
  return useQuery({
    queryKey: ["vendor-names"],
    queryFn: async () => {
      try {
        const response = await instance.get(`/api/vendor/names/`);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

// 18-10-24
export const useDisapproveVendorItems = () => {
  return useMutation({
    mutationFn: async (vendor_id) => {
      const response = await instance.post(
        `/api/item-master/vendor/${vendor_id}/unverify-all-items/`
      );
      return response?.data;
    },
    onError: (data) => {
      toast.error(data?.message);
    },
    onSuccess: (data) => {
      toast.success(data?.message, {
        autoClose: 2000
      });
    }
  });
};
export const useGetVendorNotes = ({ vendor_id, page }) => {
  return useQuery({
    queryKey: ["vendor-notes", vendor_id, page],
    queryFn: async () => {
      try {
        if (vendor_id !== undefined) {
          const response = await instance.get(
            `/api/vendor/${vendor_id}/note/?page=${page}`
          );
          return response?.data;
        }
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};
export const useCreateVendorNote = () => {
  return useMutation({
    mutationFn: async ({ vendor_id, note }) => {
      const response = await instance.post(`/api/vendor/${vendor_id}/note/`, {
        note
      });
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(data?.message);
      queryClient.invalidateQueries({ queryKey: ["vendor-notes"] });
    },
    onError: (data) => {
      toast.error(data?.message);
    }
  });
};
export const useGetVendorItemMaster = (payload) => {
  return useQuery({
    queryKey: ["vendor-item-master", payload],
    queryFn: async () => {
      const {
        vendor_id,
        human_verified,
        category_review_required,
        verified_by,
        item_code,
        item_description,
        page,
        document_uuid,
        page_size,
        sort_by_item_description,
        sort_by_item_code,
        is_bounding_box_present
      } = payload;
      let apiUrl = "";

      if (document_uuid) {
        apiUrl = `/api/item-master/vendor/${vendor_id}/?page=${page}&human_verified=${human_verified}&category_review_required=${category_review_required}&verified_by=${verified_by}&item_code=${item_code}&item_description=${item_description}&document_uuid=${document_uuid}&sort_by_item_description=${sort_by_item_description}&sort_by_item_code=${sort_by_item_code}${is_bounding_box_present == "true" || is_bounding_box_present == "false" ? `&is_bounding_box_present=${is_bounding_box_present}` : ""}`;
      } else {
        apiUrl = `/api/item-master/vendor/${vendor_id}/?page=${page}&human_verified=${human_verified}&category_review_required=${category_review_required}&verified_by=${verified_by}&item_code=${item_code}&item_description=${item_description}&document_uuid=${document_uuid}&page_size=${page_size}&sort_by_item_description=${sort_by_item_description}&sort_by_item_code=${sort_by_item_code}${is_bounding_box_present == "true" || is_bounding_box_present == "false" ? `&is_bounding_box_present=${is_bounding_box_present}` : ""}`;
      }
      try {
        const response = await instance.get(apiUrl);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};
export const useGetAllUsers = () => {
  return useQuery({
    queryKey: ["user-names"],
    queryFn: async () => {
      try {
        const response = await instance.get(`/api/user/`);
        return response?.data;
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};

// 21-10-24
export const useUpdateVendorItemMaster = () => {
  return useMutation({
    mutationFn: async ({ item_uuid, data }) => {
      const response = await instance.put(
        `/api/item-master/${item_uuid}/details/`,
        {
          ...data
        }
      );
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${data?.data?.updated_fields?.length > 0
          ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
          : ``
        }`,
        {
          autoClose: 2000
        }
      );
    },
    onError: (data) => {
      toast.error(data?.message);
    }
  });
};

export const useMergeVendorItemMaster = () => {
  return useMutation({
    mutationFn: async ({ items_to_merge, master_item_uuid }) => {
      const response = await instance.post(`/api/item-master/merge/`, {
        items_to_merge,
        master_item_uuid
      });
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${data?.data?.updated_fields?.length > 0
          ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
          : ``
        }`,
        {
          autoClose: 2000
        }
      );

      queryClient.invalidateQueries(["vendor-item-master"]);
    },
    onError: (data) => {
      toast.error(data?.message);
    }
  });
};

export const useDeleteItemMaster = () => {
  return useMutation({
    mutationFn: async ({ type, item_uuid }) => {
      const response = await instance.delete(
        `/api/item-master/${item_uuid}/delete/?type=${type}`
      );
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${data?.data?.updated_fields?.length > 0
          ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
          : ``
        }`,
        {
          autoClose: 2000
        }
      );

      queryClient.invalidateQueries(["vendor-item-master"]);
    },
    onError: (data) => {
      toast.error(data?.message);
    }
  });
};

export const useGetItemMasterPdfs = (item_uuid) => {
  return useQuery({
    queryKey: ["vendor-item-master-pdfs", item_uuid],
    queryFn: async () => {
      try {
        if (item_uuid) {
          const response = await instance.get(
            `/api/item-master/${item_uuid}/pdf/`
          );
          return response?.data;
        }
      } catch (error) {
        return error?.response?.data;
      }
    }
  });
};
export const useBulkUpdateVendorItems = () => {
  return useMutation({
    mutationFn: async ({ items }) => {
      const response = await instance.put(
        `/api/item-master/bulk-update/`,
        items
      );
      return response?.data;
    },
    onSuccess: (data) => {
      toast.success(
        `${data?.message} ${data?.data?.updated_fields?.length > 0
          ? `Updated Fields:-${data?.data?.updated_fields?.join(" , ")}`
          : ``
        }`,
        {
          autoClose: 2000
        }
      );

      queryClient.invalidateQueries(["vendor-item-master"]);
    },
    onError: (data) => {
      toast.error(data?.message);
    }
  });
};

const getVendorDetails = async (vendorId) => {
  const body = {
    vendor_id: vendorId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_vendor_details`,
    body
  );
  return response.data;
};

const getVendorBranchDetails = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/get_vendor_branch_details`,
    body
  );
  return response.data;
};

const saveVendorDetails = async (vendorDetails) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/save_vendor_details`,
    vendorDetails
  );
  return response.data;
};

const getVendorBranches = async (body) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_all_vendor_branch_data`,
    body
  );
  return response.data;
};

const saveBranchDetails = async (branchDetails) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/save_vendor_branch_details`,
    branchDetails
  );
  return response.data;
};

const findSimilarVendors = async (similarity, vendorId) => {
  const body = {
    similarity,
    vendor_id: vendorId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_similar_vendors_data`,
    body
  );
  return response.data;
};

const combineVendors = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_vendors`,
    data
  );
  return response.data;
};

const combineVendorBranches = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_vendor_branches`,
    data
  );
  return response.data;
};

const getVendorBranchPdfs = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId
  };
  const response = await axiosInstance.post(
    `${API_URL}/get_vendor_branch_pdfs`,
    body
  );
  return response.data;
};

const renameVendor = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/rename_vendor_branch_address`,
    data
  );
  return response.data;
};

const getItemsForVendor = async (body) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_item_master_for_vendor`,
    body
  );
  return response.data;
};

const approveItem = async (body) => {
  // const body = {
  //   item_uuid,
  //   category,
  //   branch_id: branchId,
  //   human_verified: humanVerified,
  //   item_code: itemCode,
  //   item_description: itemDescription,
  // };
  const response = await axiosInstance.post(
    `${API_URL}/update_item_master_details`,
    body
  );
  return response.data;
};

const getItemPdfs = async (item_uuid, vendor_id) => {
  const body = {
    item_uuid,
    vendor_id
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_item_master_pdf_links`,
    body
  );
  return response.data;
};

const deleteVendor = async (vendorId) => {
  const body = {
    vendor_id: vendorId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/delete_vendor`,
    body
  );
  return response.data;
};

const deleteVendorBranch = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/delete_vendor_branch`,
    body
  );
  return response.data;
};

const addNewVendor = async (vendorName) => {
  const body = {
    vendor_name: vendorName
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/create_vendor`,
    body
  );
  return response.data;
};

const getAdditionalData = async () => {
  const body = {
    category_choices: true,
    common_issues_in_invoice_extraction: false,
    processed_table_header_candidates: true
  };
  const response = await axiosInstance.post(
    `${API_URL}/get_additional_data`,
    body
  );
  return response.data;
};

const mergeItemMaster = async (
  vendorId,
  branchId,
  masterItemUUID,
  itemsToMerge
) => {
  const data = {
    vendor_id: vendorId,
    branch_id: branchId,
    master_item_uuid: masterItemUUID,
    items_to_merge: itemsToMerge
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_item_masters`,
    data
  );
  return response.data;
};

const deleteItemMaster = async (vendorId, branchId, itemUUID, softDelete) => {
  const data = {
    vendor_id: vendorId,
    branch_id: branchId,
    item_uuid: itemUUID,
    soft_delete: softDelete
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/delete_item_master`,
    data
  );
  return response.data;
};

const migrateBranch = async (payload) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/migrate_branch_vendor`,
    payload
  );
  return response.data;
};

const disapproveAllItems = async (vendorId) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/unverify_all_items`,
    { vendor_id: vendorId }
  );
  return response.data;
};

const createOrUpdateItemMasterIssues = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/engineering_issues/create_or_update_item_master_issue`,
    data
  );
  return response.data;
};

export const useFetchItemMasterIssues = (itemList) => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["itemMasterIssues", itemList?.[0]?.item_uuid],
    queryFn: async () => {
      try {
        const body = {
          item_uuid: itemList[0].item_uuid
        };
        const response = await axiosInstance.post(
          `/engineering_issues/get_item_master_issue`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["itemMasterIssues", itemList[0]?.item_uuid],
        data
      );
    },
    enabled: !!itemList?.length > 0
  });
};

export const useGetInvoiceHeaderExceptionsForVendor = (vendorId) => {
  return useQuery({
    queryKey: ["invoiceHeaderExceptions", vendorId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL}/vendor_page/get_invoice_header_exceptions?vendor_id=${vendorId}`
      );
      return response.data;
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.setQueryData(
        ["invoiceHeaderExceptions", vendorId],
        data
      );
    },
    enabled: !!vendorId
  });
};

export const useUpdateInvoiceHeaderExceptionsForVendor = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInstance.post(
        `${API_URL}/vendor_page/update_invoice_header_exceptions`,
        data
      );
      return response.data;
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([
        "invoiceHeaderExceptions",
        variables.vendorId
      ]);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error("Error updating invoice header exceptions");
    }
  });
};


export {
  addNewVendor,
  approveItem,
  combineVendorBranches,
  combineVendors,
  createOrUpdateItemMasterIssues,
  deleteItemMaster,
  deleteVendor,
  deleteVendorBranch,
  disapproveAllItems,
  findSimilarVendors,
  getAdditionalData,
  getItemPdfs,
  getItemsForVendor,
  getVendorBranchDetails,
  getVendorBranches,
  getVendorBranchPdfs,
  // getVendorConsolidation,
  getVendorDetails,
  mergeItemMaster,
  migrateBranch,
  renameVendor,
  saveBranchDetails,
  saveVendorDetails
};
