import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const access_token = localStorage.getItem("token"); 
  const [searchParams]=useSearchParams();
  // navigate(`${import.meta.env.VITE_APP_OLD_UI_STAGING_UI}/vendor-consolidation?token=${token}&user_id=${user_id}&refresh_token=${refresh_token}&username=${username}&role=${role}&user_email=${user_email}&first_name=${first_name}&last_name=${last_name}`)

  let token = searchParams.get("token");
  let user_id = searchParams.get("user_id");
  let refresh_token = searchParams.get("refresh_token");
  let username = searchParams.get("username");
  let first_name = searchParams.get("first_name");
  let last_name = searchParams.get("last_name");
  let user_email = searchParams.get("user_email");
  let role = searchParams.get("role");


   if(!access_token){
    localStorage.setItem('token',token);
    localStorage.setItem('user_id',user_id);
    localStorage.setItem('refresh_token',refresh_token);
    localStorage.setItem('username',username);
    localStorage.setItem('first_name',first_name);
    localStorage.setItem('last_name',last_name);
    localStorage.setItem('user_email',user_email);
    localStorage.setItem('role',role);

   }
  return (token||access_token) ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
